import React from "react";
import { Grid, Typography, Container, Tab, Tabs } from "@mui/material";

import AuctionBiddingController, {
  Props,
} from "./AuctionBiddingController.web";

// Customizable Area Start

import "../assets/styles/index.css";
import { notification } from "./assets";
// Customizable Area End

class AuctionBidding extends AuctionBiddingController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  liveAuctionView = () => {
    return (
      <>
        {this.state.live_auction &&
          this.state.live_auction.map((item) => (
            <Grid
              data-test-id={"liveAuctionId"}
              container
              item
              xs={12}
              style={{
                margin: "20px 0px",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "#d3dbe8",
              }}
              key={item.id}
            >
              <Grid container>
                <Grid item>
                  <img
                    src={item.attributes.product_images[0].url}
                    alt="image"
                    style={{
                      height: "120px",
                      borderRadius: "20px",
                      width: "120px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs
                  style={{ paddingLeft: "20px", paddingRight: "10px" }}
                >
                  <Typography
                    className="textStyle"
                    style={{ fontWeight: 300, color: "black" }}
                  >
                    Product Name : {item.attributes.product_name}
                  </Typography>
                  <Typography className="textStyle" style={{ fontWeight: 300 }}>
                    Minimum Price : ${item.attributes.minimum_bid}
                  </Typography>
                  <Typography
                    data-test-id={"liveAuctionStartTimeId"}
                    style={{ fontWeight: 200 }}
                    className="textStyle"
                  >
                    Auction start date :{" "}
                    {(item: { attributes: { start_time: Date } }) =>
                      this.auctionStart(item.attributes.start_time)
                    }
                  </Typography>
                  <Typography
                    data-test-id={"liveAuctionEndTimeId"}
                    className="textStyle"
                  >
                    Auction start date :{" "}
                    {(item: { attributes: { end_time: Date } }) =>
                      this.auctionEnd(item.attributes.end_time)
                    }
                  </Typography>
                  <Typography className="textStyle">
                    Current Bid Price : ${item.attributes.maximum_bid_placed}
                  </Typography>
                </Grid>
                <div
                  onClick={() =>
                    this.navigateAuctionBiddingDetailsPage(item.id)
                  }
                  data-test-id={"navigatAuctionBiddingDetailId"}
                >
                  Details
                </div>
              </Grid>
            </Grid>
          ))}
        {this.state.live_auction == null && (
          <Grid item xs={12} style={{ margin: "20px 0px" }}>
            <Typography className={"textStyle"}>No Product Found</Typography>
          </Grid>
        )}
      </>
    );
  };

  upcomingAuctionView = () => {
    return (
      <>
        {this.state.upcoming_auction &&
          this.state.upcoming_auction.map((item) => (
            <Grid
              item
              xs={12}
              style={{
                margin: "20px 0px",
                backgroundColor: "#d3dbe8",
                padding: "10px",
                borderRadius: "10px",
              }}
              key={item.id}
            >
              <Grid container>
                <Grid item>
                  <img
                    src={item.attributes.product_images[0].url}
                    alt="image"
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "20px",
                    }}
                  />
                </Grid>
                <Grid item xs style={{ paddingLeft: "20px" }}>
                  <Typography className="textStyle">
                    Product Name : {item.attributes.product_name}
                  </Typography>
                  <Typography className="textStyle">
                    Minimum Price : ${item.attributes.minimum_bid}
                  </Typography>
                  <Typography data-test-id="movmentId" className="textStyle">
                    Auction start date :{" "}
                    {(item: { attributes: { start_time: Date } }) =>
                      this.upcomingAuctionStart(item.attributes.start_time)
                    }{" "}
                  </Typography>
                  <Typography data-test-id="movmentEndId" className="textStyle">
                    Auction start date :{" "}
                    {(item: { attributes: { end_time: Date } }) =>
                      this.upcomingAuctionEnd(item.attributes.end_time)
                    }
                  </Typography>
                </Grid>

                <div
                  onClick={() =>
                    this.navigateAuctionBiddingDetailsPage(item.id)
                  }
                  data-test-id={"navigateDetailsPageId"}
                >
                  Details
                </div>
              </Grid>
            </Grid>
          ))}
        {this.state.upcoming_auction == null && (
          <Grid item xs={12} style={{ margin: "20px 0px" }}>
            <Typography className="textStyle">No Product Found</Typography>
          </Grid>
        )}
      </>
    );
  };

  header = () => {
    return (
      <Grid
        container
        xs={12}
        style={{ backgroundColor: "#e3dede", flexBasis: "0%" }}
      >
        <Grid xs={11}>
          <h2>Auction Bidding</h2>
        </Grid>
        <Grid xs={1}>
          <div
            onClick={() => this.navigateAuctionBiddingNotificationPage()}
            data-test-id={"navigateNotificationID"}
          >
            <img
              src={notification}
              alt="image"
              style={{ width: "40px", height: "40px", marginTop: "10px" }}
            />
            {/* </Link> */}
          </div>
        </Grid>
      </Grid>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start

    return (
      <>
        {this.header()}
        <Container maxWidth={"md"}>
          <Tabs
            value={this.state.selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onTabChange}
            className="tabStyle"
            classes={{
              indicator: "indicator",
            }}
            data-test-id="auction_tab"
          >
            <Tab label="Live Auction" />
            <Tab label="Upcoming Auction" />
          </Tabs>
          <Grid
            data-test-id={"tabAuctionId"}
            container
            style={{ paddingLeft: "20px" }}
          >
            {this.state.selectedTab == 0 && this.liveAuctionView()}
            {this.state.selectedTab == 1 && this.upcomingAuctionView()}
          </Grid>
        </Container>
      </>
    );
  }
  // Customizable Area End
}

export default AuctionBidding;
