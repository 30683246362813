// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Cfspamengine2 from "../../blocks/cfspamengine2/src/Cfspamengine2";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cftenniscoins23 from "../../blocks/cftenniscoins23/src/Cftenniscoins23";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Emojis from "../../blocks/emojis/src/Emojis";
import Location from "../../blocks/location/src/Location";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import EventRegistration from "../../blocks/eventregistration/src/EventRegistration";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import AddressManagement from "../../blocks/addressmanagement/src/AddressManagement";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Videos from "../../blocks/videos/src/Videos";
import Trending from "../../blocks/trending/src/Trending";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import AuctionBidding from "../../blocks/auctionbidding/src/AuctionBidding";
import LoyaltySystem from "../../blocks/loyaltysystem/src/LoyaltySystem";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import PerformanceTracker from "../../blocks/performancetracker/src/PerformanceTracker";
import PtCoursesScreen from "../../blocks/performancetracker/src/PtCoursesScreen";
import PtTestsForCourseScreen from "../../blocks/performancetracker/src/PtTestsForCourseScreen";
import PtStudentsOnCourseScreen from "../../blocks/performancetracker/src/PtStudentsOnCourseScreen";
import PtTestScoresForStudentScreen from "../../blocks/performancetracker/src/PtTestScoresForStudentScreen";
import PtBasicUserCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserCoursesScreen";
import PtBasicUserAllCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserAllCoursesScreen";
import PtStudentRankingScreen from "../../blocks/performancetracker/src/PtStudentRankingScreen";
import PtStudentInfoScreen from "../../blocks/performancetracker/src/PtStudentInfoScreen";
import VideoTrimmer from "../../blocks/videotrimmer/src/VideoTrimmer";
import Followers from "../../blocks/followers/src/Followers";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Reservations from "../../blocks/reservations/src/Reservations";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Geofence from "../../blocks/geofence/src/Geofence";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import Cftenniscoins12 from "../../blocks/cftenniscoins12/src/Cftenniscoins12";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";


const routeMap = {
Cfspamengine2:{
 component:Cfspamengine2,
path:"/Cfspamengine2"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cftenniscoins23:{
 component:Cftenniscoins23,
path:"/Cftenniscoins23"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Emojis:{
 component:Emojis,
path:"/Emojis"},
Location:{
 component:Location,
path:"/Location"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
EventRegistration:{
 component:EventRegistration,
path:"/EventRegistration"},
AutomaticRenewals:{
 component:AutomaticRenewals,
path:"/AutomaticRenewals"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
AddressManagement:{
 component:AddressManagement,
path:"/AddressManagement"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Videos:{
 component:Videos,
path:"/Videos"},
Trending:{
 component:Trending,
path:"/Trending"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
AuctionBidding:{
 component:AuctionBidding,
path:"/AuctionBidding"},
LoyaltySystem:{
 component:LoyaltySystem,
path:"/LoyaltySystem"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
PtCoursesScreen:{
 component:PtCoursesScreen,
path:"/PtCoursesScreen"},
PtTestsForCourseScreen:{
 component:PtTestsForCourseScreen,
path:"/PtTestsForCourseScreen"},
PtStudentsOnCourseScreen:{
 component:PtStudentsOnCourseScreen,
path:"/PtStudentsOnCourseScreen"},
PtTestScoresForStudentScreen:{
 component:PtTestScoresForStudentScreen,
path:"/PtTestScoresForStudentScreen"},
PtBasicUserCoursesScreen:{
 component:PtBasicUserCoursesScreen,
path:"/PtBasicUserCoursesScreen"},
PtBasicUserAllCoursesScreen:{
 component:PtBasicUserAllCoursesScreen,
path:"/PtBasicUserAllCoursesScreen"},
PtStudentRankingScreen:{
 component:PtStudentRankingScreen,
path:"/PtStudentRankingScreen"},
PtStudentInfoScreen:{
 component:PtStudentInfoScreen,
path:"/PtStudentInfoScreen"},
VideoTrimmer:{
 component:VideoTrimmer,
path:"/VideoTrimmer"},
Followers:{
 component:Followers,
path:"/Followers"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
QrCodes:{
 component:QrCodes,
path:"/QrCodes"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
Cftenniscoins12:{
 component:Cftenniscoins12,
path:"/Cftenniscoins12"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;